import axios from '@/libs/api.request'

export const getDocINAPI = () => {
    return axios.request({
        url: 'document-income',
        method: 'get'
    })
}

export const getDocINByIdAPI = (id) => {
    return axios.request({
        url: 'document-income/' + id,
        method: 'get'
    })
}

export const createDocINAPI = (credentials) => {
    return axios.request({
        url: 'document-income',
        data: credentials,
        method: 'post'
    })
}

export const updateDocINAPI = (credentials) => {
    return axios.request({
        url: 'document-income/' + credentials.doc_id,
        data: credentials,
        method: 'put'
    })
}



